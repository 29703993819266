import React from 'react'
import ProjectGrid from '../components/gridBlocks/projectGrid'

export default function Projects() {
  return (
    <>
      <ProjectGrid />
    </>
  )
}
